import {
  MatchItemData,
  MatchState,
  ParseStatic,
  StaticOrientation,
  TeamType,
} from '@mediacoach-ui-library/global'
import {
  AssetMatch,
  IMatch,
  Match,
  MatchStreaming,
  MatchTeam,
  McpStorableMatch,
} from '@core/models/dto/match.dto'
import { getLocalDate } from '@core/utils/date.utils'
import {
  getCompetitionWithLogos,
  getSeasonAndCompetitionBySeasonIdAndCompetitionId,
} from '@core/utils/season.utils'
import { mapPlayer } from './player.utils'
import { ApiMatch, ApiMatchTeam } from '@core/models/models/match.models'
import { ApiPlayerLastMatch, ApiPlayerMatch, ApiSquad } from '@core/models/models/player.models'
import { ApiTeamMatch } from '@core/models/models/team.models'
import { McpTeamInfo } from '@core/models/dto/team.dto'

export const orderGroupByLiveFirst = (item: IMatch) => (item.isLive ? 0 : 1)

export const parseMatchSchedule = (match: ApiTeamMatch | ApiPlayerLastMatch, lang): any =>
  match
    ? {
        ...match,
        schedule: parseSchedule(match, lang),
      }
    : null

export const parseSchedule = (
  match: ApiTeamMatch | ApiPlayerLastMatch | ApiMatch | Match | ApiPlayerMatch,
  lang,
): any =>
  match.schedule && {
    ...match.schedule,
    translated: getLocalDate(match.schedule.scheduledDate, lang),
  }

export const parseMatchToLocalStorage = (match: Match): McpStorableMatch => {
  const { seasonId, competitionId } = match as ApiMatch | Match
  const { competition } = match as unknown as ApiPlayerMatch
  return {
    id: match.id,
    seasonId: seasonId || competition.seasonId,
    competitionId: competitionId || competition.id,
    home: {
      score: match.home.score,
      team: { parsedImages: match.home.team.parsedImages },
    },
    away: {
      score: match.away.score,
      team: { parsedImages: match.away.team.parsedImages },
    },
  }
}

export const parseMatch = (match: ApiMatch | Match | ApiPlayerMatch, lang, seasons?): Match => {
  const competitionData = { season: undefined, competition: undefined }
  const { homeTeam, awayTeam } = match as ApiMatch | ApiPlayerMatch
  const { seasonId, competitionId, assets } = match as ApiMatch | Match
  const { competition } = match as ApiPlayerMatch
  const { home, away, streaming, assetsAvailability, incidence, state, season, streamsVoD } =
    match as Match
  const cid = competitionId || competition.id

  if (!competition && seasons && seasonId && cid) {
    const seasonAndCompetition = getSeasonAndCompetitionBySeasonIdAndCompetitionId(
      seasonId,
      cid,
      seasons,
    )
    competitionData.season = seasonAndCompetition.season
    competitionData.competition = seasonAndCompetition.competition
  } else {
    competitionData.competition = {
      ...competition,
      ...getCompetitionWithLogos(competition),
    }
    competitionData.season = season
  }

  return {
    id: match.id,
    seasonId: seasonId || competition.seasonId,
    competitionId: cid,
    isLive: !!(match.state && (state.minute || match.state.name === MatchState.LineupReady)),
    state: {
      ...match.state,
      name: match.schedule && (match.schedule.toBeConfirmed ? null : match.state.name),
      minute: match.state.name !== MatchState.HalfTime ? match.state?.minuteDescription : null,
    },
    venue: match.venue,
    schedule: parseSchedule(match, lang),
    home: home || parseMatchTeam(homeTeam),
    away: away || parseMatchTeam(awayTeam),
    streaming: streaming || ({} as MatchStreaming),
    matchdayNumber: match.matchdayNumber,
    headerMetricResults: match.headerMetricResults,
    matchday: { number: match.matchdayNumber },
    assetsAvailability,
    incidence,
    assets: (assets || []).map((asset: any) => ({
      ...asset,
      type: asset.type.name,
      contentType: asset.qualifierContentType,
      sur: asset.url,
      qualifiers: asset.qualifiers.map((qualifier) => ({
        ...qualifier,
        name: qualifier.type.name,
      })),
      quality: (asset.qualifiers.find((qualifier) => qualifier.type.name === 'DataQuality') || {})
        .value,
    })),
    ...competitionData,
    streamsVoD,
  }
}

export const parseMatchTeam = (team: ApiMatchTeam): MatchTeam => {
  const { shortName, name, statics, squad, lineup, score, abbreviation, manager } = team

  return {
    team: {
      id: team.teamId,
      name: shortName || name || '',
      abbreviation,
      manager,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      parsedImages: { Portrait: { Small2x: ParseStatic(statics, StaticOrientation.Portrait) } },
      squad: ((squad || ({} as ApiSquad)).players || []).map((p) =>
        mapPlayer(p, '', '', team.teamId),
      ),
      lineup: ((lineup || ({} as ApiSquad)).players || []).map((p) =>
        mapPlayer(p, '', '', team.teamId),
      ),
    },
    score: { standardTimeScore: score },
  }
}

export const toMatchItemData = (match: Match): MatchItemData =>
  match
    ? {
        home: match.home as any,
        away: match.away as any,
        state: match.state,
      }
    : null

const mapComparableMatchTeam = (match: AssetMatch, teamType: TeamType) => ({
  lineup: { players: ((match.lineup || {})[teamType] || []).values },
  squad: { players: ((match.squad || {})[teamType] || []).values },
})

export const mapComparableMatch = (match: AssetMatch) => ({
  ...match,
  homeTeam: mapComparableMatchTeam(match, TeamType.Home),
  awayTeam: mapComparableMatchTeam(match, TeamType.Away),
})

export const getMatchIdByLocation = (): string => location.pathname.replace('/match-detail/', '')

export const updateScoreOf = (team: 'home' | 'away', oldMatch, newMatch): McpTeamInfo | any => ({
  team: oldMatch[team].team,
  score: {
    ...oldMatch[team].score,
    standardTimeScore: newMatch[`${team}Team`].score,
  },
})
