import { AssetMatch, Match, MatchTeam, MatchTeamMetrics } from '@core/models/dto/match.dto'
import {
  ParseStatic,
  ParseStatics,
  PLACEHOLDER_IMAGES,
  SafeObjectData,
  StaticOrientation,
  TeamType,
} from '@mediacoach-ui-library/global'
import { TWO_TEAM_CODES } from '@core/constants/matches.constants'
import { McpTeam, McpTeamHeader } from '@core/models/dto/team.dto'
import { ApiMatch } from '@core/models/models/match.models'
import { getTeamImageFromAssetMatch } from '@features/matches/utils/matches.utils'
import { ApiTeam, ApiTeamTeams } from '@core/models/models/team.models'
import { mapMetricsAsList } from '@core/utils/metrics.utils'
import { McpFormattedMetricsConfig } from '@core/models/dto/metric.dto'
import { SegmentOption } from '@core/models/models/common.models'

export function getTeamLogo(match: MatchTeam): string {
  return SafeObjectData(match, 'team.parsedImages.Portrait.Small2x', '')
}

export function getTeamSegmentOptions({ homeTeam, awayTeam }: ApiMatch): SegmentOption[] {
  return [homeTeam, awayTeam].map((team, i) => ({
    id: i === 0 ? TeamType.Home : TeamType.Away,
    label: team.shortName || team.name,
    image: ParseStatic(team.statics, StaticOrientation.Portrait),
  }))
}

export function getTeamHeader(match: Match): McpTeamHeader[] {
  return Object.keys(TWO_TEAM_CODES).map((teamCode) => {
    const { name, parsedImages } = match[TWO_TEAM_CODES[teamCode]].team
    const logo = parsedImages.Portrait.Small2x
    return {
      text: name,
      hideText: !!logo,
      isTitle: true,
      logo: logo || `${PLACEHOLDER_IMAGES.TEAM} `,
    }
  })
}

export function parseMatchTeamMetrics(
  { home, away }: AssetMatch,
  { metricsOverview }: { metricsOverview: any },
): MatchTeamMetrics {
  const config: McpFormattedMetricsConfig = {
    left: { img: getTeamImageFromAssetMatch(home), title: home.team.name },
    right: { img: getTeamImageFromAssetMatch(away), title: away.team.name },
    isSwitchable: true,
    fromMatch: true,
    averageValueKeys: ['homeMetricResult', 'awayMetricResult'],
    matchValueKeys: ['homeMetricResult', 'awayMetricResult'],
    accumulatedValueKeys: ['homeMetricResult', 'awayMetricResult'],
  }
  const { id, ...overview } = mapMetricsAsList({
    ...config,
    metricsProperty: metricsOverview,
  })

  return { ...overview, overviewId: id } as MatchTeamMetrics
}

export function parseMatchTeamSegmentOptions(match: AssetMatch): SegmentOption[] {
  return Object.values(TWO_TEAM_CODES).map((code) => ({
    id: code,
    label: match[code].team.name,
    image: getTeamImageFromAssetMatch(match[code]),
  }))
}

export function mapTeam(
  team: ApiTeamTeams | ApiTeam,
  seasonId: string,
  competitionId: string,
): McpTeam {
  return {
    ...team,
    ...ParseStatics(team.statics, [StaticOrientation.Portrait]),
    name: team.shortName || team.name,
    seasonId,
    competitionId,
  }
}
