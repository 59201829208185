<div class="mcp-scrollable-item-selector" mcpDragNScroll="horizontal">
  @for (item of items; track item.id) {
    @if (item?.hasDivider) {
      <div class="mcp-scrollable-item-selector__divider">
          <span class="mcp-scrollable-item-selector__heading-text">
            {{ item.dividerTitle | translate }}</span>
      </div>
    }
    <div
      mcpDragNScrollItem
      [id]="item.id"
      class="mcp-scrollable-item-selector__item"
      [class.selected]="$index === selectedIdx"
      (click)="onClick(item, $index)"
    >
      <img
        class="mcp-scrollable-item-selector__img"
        draggable="false"
        tooltipPosition="bottom"
        [class.large]="large"
        [alt]="item?.metricPlayerName ?? item?.formattedName ?? item?.name"
        [mcuiTooltip]="item?.metricPlayerName ?? item?.formattedName ?? item?.name"
        [tooltipDisabled]="isMobile || isTooltipDisabled"
        [appDefaultImage]="placeholder"
        [srcUrl]="item?.portraitLogo"
      />
      @if (hasTag) {
        <span class="mcp-scrollable-item-selector__tag">
          {{ item?.metricPlayerName ?? item?.formattedName ?? item?.name }}
        </span>
      }
    </div>
  }
</div>
