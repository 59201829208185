import { Injectable, Injector } from '@angular/core'
import { Router } from '@angular/router'
import { Observable, ReplaySubject, Subject } from 'rxjs'
import { filter, takeUntil } from 'rxjs/operators'
import { StepHandler, TourStep, TourStepInfo } from '../models/tour-step.models'
import {
  ARROW_SIZE,
  DISTANCE_FROM_TARGET,
  NO_POSITION,
  SCROLLBAR_SIZE,
} from '../constants/tour.constants'
import { DomService } from './dom.service'
import { StepDrawerService } from './step-drawer.service'
import { EventListenerService } from './event-listener.service'
import { TourStepsContainerService } from './tour-steps-container.service'
import { TourBackdropService } from './tour-backdrop.service'
import { ElementPositioning, StepActionType } from '../enums/tour.enum'
import { TourStepDoesNotExist, TourStepOutOfRange } from '../models/tour-step-error.models'
import { waitUntilIsRendered } from '../utils/tour.utils'
import { TourOptions } from '../models/tour-options.models'
import { DebuggableBaseService } from './debuggable-base.service'

/* eslint-disable @typescript-eslint/no-unused-expressions */
@Injectable({ providedIn: 'root' })
export class TourStepService extends DebuggableBaseService implements StepHandler {
  private _currentStep: TourStep
  private _winTopPosition = 0
  private _winBottomPosition = 0
  private _stepsObserver: ReplaySubject<TourStepInfo> = new ReplaySubject<TourStepInfo>()
  private _options: TourOptions
  private _destroy$$: Subject<void>

  private get _currentElement(): Element {
    return document.querySelector(this._currentStep.selector)
  }

  constructor(
    private readonly _backdrop: TourBackdropService,
    private readonly _events: EventListenerService,
    private readonly _stepContainer: TourStepsContainerService,
    private readonly _dom: DomService,
    private readonly _stepDrawer: StepDrawerService,
    private readonly _router: Router,
    private readonly _injector: Injector,
  ) {
    super(_injector)
    this._initViewportPositions()
  }

  startTour(options: TourOptions): Observable<TourStepInfo> {
    this._options = options
    this._backdrop.setup(options)
    this._stepsObserver = new ReplaySubject<TourStepInfo>()
    this._stepContainer.init()
    this._dom.setDocumentHeight()

    this._tryShowStep(this._getCurrentStep(StepActionType.next), StepActionType.next)
    this._handleDomEvents()
    this._subscribeToStepsUpdates()
    return this._stepsObserver.asObservable()
  }

  close(isCompleted?: boolean) {
    this.cleanUp()
    this._notifyTourIsFinished(isCompleted ? StepActionType.done : StepActionType.close)
    this.logger.info('The tour has been closed.')
    this._dom.winRef.scrollTo(0, 0)
  }

  cleanUp() {
    this._removeCurrentStep()
    this._removeListeners()
    this._backdrop.remove()
  }

  prev() {
    this._removeCurrentStep()
    !!this._currentStep?.onPrev && this._currentStep?.onPrev()
    this._tryShowStep(this._getCurrentStep(StepActionType.prev), StepActionType.prev)
  }

  next() {
    this._removeCurrentStep()
    !!this._currentStep?.onNext && this._currentStep?.onNext()
    this._tryShowStep(this._getCurrentStep(StepActionType.next), StepActionType.next)
  }

  getOptions(): TourOptions {
    return this._options
  }

  private _initViewportPositions() {
    this._winTopPosition = 0
    this._winBottomPosition = this._dom.winRef.innerHeight - SCROLLBAR_SIZE
  }

  private _handleDomEvents() {
    this._destroy$$ = new Subject<void>()
    this._events.startListening()
    this._subscribeToScrollEvents()
    this._subscribeToResizeEvents()
  }

  private _subscribeToStepsUpdates() {
    this._stepContainer.stepHasBeenModified
      .pipe(
        takeUntil(this._destroy$$),
        filter((step) => this._currentStep && this._currentStep.name === step.name),
      )
      .subscribe((updatedStep) => (this._currentStep = updatedStep))
  }

  private _getCurrentStep(actionOrName: StepActionType | string): TourStep {
    this._currentStep = Object.values(StepActionType).includes(actionOrName as StepActionType)
      ? this._stepContainer.get(actionOrName as StepActionType)
      : this._stepContainer.getStepByName(actionOrName)
    return this._currentStep
  }

  private async _tryShowStep(step: TourStep, actionType: StepActionType) {
    //timeout
    const timeout = step.timeout || this._options.waitingTime
    if (timeout > 100) {
      this._backdrop.remove()
    }

    // new navigation
    if (step.navigateTo) {
      this._backdrop.remove()
      await this._router.navigate([step.navigateTo])
    } else if (step.dependsOn && actionType === StepActionType.prev) {
      this._tryShowStep(this._getCurrentStep(step.dependsOn), actionType)
      return
    }

    // before drawing stuff
    if (step.beforeShow) {
      try {
        await step.beforeShow()
      } catch (e) {
        this.logger.error('Unable to run beforeShow callback', e)
      }
    }

    //drawing
    await Promise.all(
      [step.selector, ...(step.waitUntilRendered || [])].map((selector) =>
        waitUntilIsRendered(selector),
      ),
    )

    setTimeout(() => {
      try {
        this._showStep(actionType)
      } catch (error) {
        if (error instanceof TourStepDoesNotExist) {
          this._tryShowStep(this._getCurrentStep(actionType), actionType)
        } else if (error instanceof TourStepOutOfRange) {
          this.logger.error('Forcing the tour closure: First or Last step not found in the DOM.')
          this.close()
        } else {
          throw new Error(error)
        }
      }
    }, timeout)
  }

  private async _showStep(actionType: StepActionType) {
    if (this._currentStep == null) {
      throw new TourStepDoesNotExist('')
    }

    this._notifyStepClicked(actionType)
    // Scroll the element to get it visible if it's in a scrollable element
    this._scrollIfElementBeyondOtherElements()
    this._backdrop.draw(this._currentStep)
    this._drawStep(this._currentStep)
    this._scrollIfStepAndTargetAreNotVisible()

    if (this._currentStep.afterShow) {
      try {
        await this._currentStep.afterShow()
      } catch (e) {
        this.logger.error('Unable to run afterShow callback', e)
      }
    }
    if (!this._currentStep.preventNavigation) {
      this._stepContainer.updateStepNavigationPath(this._currentStep.name)
    }
  }

  private _notifyStepClicked(actionType: StepActionType) {
    const stepInfo: TourStepInfo = {
      number: this._stepContainer.getStepNumber(this._currentStep.name),
      step: this._currentStep,
      actionType,
    }
    this._stepsObserver.next(stepInfo)
  }

  private _notifyTourIsFinished(actionType: StepActionType) {
    if (this._currentStep) {
      !!this._currentStep?.onDone && this._currentStep.onDone()
    }
    this._notifyStepClicked(actionType)
    this._stepsObserver.complete()
  }

  private _removeCurrentStep() {
    if (this._currentStep) {
      this._stepDrawer.detach(this._currentStep)
    }
  }

  private _scrollIfStepAndTargetAreNotVisible() {
    this._scrollWhenTargetOrStepAreHiddenBottom()
    this._scrollWhenTargetOrStepAreHiddenTop()
  }

  private _scrollWhenTargetOrStepAreHiddenBottom() {
    const totalTargetBottom = this._getMaxTargetAndStepBottomPosition()
    if (totalTargetBottom > this._winBottomPosition) {
      this._dom.winRef.scrollBy(0, totalTargetBottom - this._winBottomPosition)
    }
  }

  private _scrollWhenTargetOrStepAreHiddenTop() {
    const totalTargetTop = this._getMaxTargetAndStepTopPosition()
    if (totalTargetTop < this._winTopPosition) {
      this._dom.winRef.scrollBy(0, totalTargetTop - this._winTopPosition)
    }
  }

  private _getMaxTargetAndStepBottomPosition(): number {
    const targetAbsoluteTop = this._dom.getElementAbsoluteTop(this._currentElement)
    if (this._currentStep.position === 'top') {
      return targetAbsoluteTop + this._currentStep.stepInstance.targetDimensions.height
    } else if (this._currentStep.position === 'bottom') {
      return (
        targetAbsoluteTop +
        this._currentStep.stepInstance.targetDimensions.height +
        this._currentStep.stepInstance.stepDimensions.height +
        ARROW_SIZE +
        DISTANCE_FROM_TARGET
      )
    } else if (this._currentStep.position === 'right' || this._currentStep.position === 'left') {
      return Math.max(
        targetAbsoluteTop + this._currentStep.stepInstance.targetDimensions.height,
        targetAbsoluteTop +
          this._currentStep.stepInstance.targetDimensions.height / 2 +
          this._currentStep.stepInstance.stepDimensions.height / 2,
      )
    }
  }

  private _getMaxTargetAndStepTopPosition() {
    const targetAbsoluteTop = this._dom.getElementAbsoluteTop(this._currentElement)
    if (this._currentStep.position === 'top') {
      return (
        targetAbsoluteTop -
        (this._currentStep.stepInstance.stepDimensions.height + ARROW_SIZE + DISTANCE_FROM_TARGET)
      )
    } else if (this._currentStep.position === 'bottom') {
      return targetAbsoluteTop
    } else if (this._currentStep.position === 'right' || this._currentStep.position === 'left') {
      return Math.min(
        targetAbsoluteTop,
        targetAbsoluteTop +
          this._currentStep.stepInstance.targetDimensions.height / 2 -
          this._currentStep.stepInstance.stepDimensions.height / 2,
      )
    }
  }

  private _scrollIfElementBeyondOtherElements() {
    const elementPosition: ElementPositioning = this._dom.isElementBeyondOthers(
      this._currentElement,
      this._currentStep.isElementOrAncestorFixed,
      'backdrop',
    )

    if (elementPosition === ElementPositioning.beneath) {
      this._dom.scrollToTheTop(this._currentElement)
    }

    if (
      elementPosition === ElementPositioning.beyond &&
      this._dom.isParentScrollable(this._currentElement)
    ) {
      this._dom.scrollIntoView(this._currentElement, this._currentStep.isElementOrAncestorFixed)
      this._currentElement?.scrollIntoView()
    }
  }

  private _subscribeToScrollEvents() {
    this._events.scrollEvent.pipe(takeUntil(this._destroy$$)).subscribe((scroll) => {
      this._winTopPosition = scroll.scrollY
      this._winBottomPosition = this._winTopPosition + this._dom.winRef.innerHeight - SCROLLBAR_SIZE
      if (this._currentStep) {
        this._backdrop.redraw(this._currentStep, scroll)
      }
    })
  }

  private _subscribeToResizeEvents() {
    this._events.resizeEvent.pipe(takeUntil(this._destroy$$)).subscribe(() => {
      if (this._currentStep) {
        this._backdrop.redrawTarget(this._currentStep)
      }
    })
  }

  private _drawStep(step: TourStep) {
    step.position =
      step.position === NO_POSITION ? this._options.stepDefaultPosition : step.position
    this._stepDrawer.attach(step)
  }

  private _removeListeners() {
    this._events.stopListening()
    this._destroy$$.next()
    this._destroy$$.complete()
  }
}

/* eslint-enable @typescript-eslint/no-unused-expressions */
