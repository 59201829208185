<ng-container
  *ngIf="{
    items: navigationData$ | async,
    currentUrl: currentUrl$ | async,
    liveMatches: hasLiveMatches$ | async,
  } as data"
>
  <div class="c-navigation-bar__container">
    <div [attr.data-cy]="'nav-bar'" class="c-navigation-bar__main">
      <div class="c-navigation-bar__item" *ngFor="let item of data?.items">
        <mcp-navigable-dropdown
          [model]="item"
          [currentUrl]="data?.currentUrl"
          [liveMatches]="data?.liveMatches"
        ></mcp-navigable-dropdown>
      </div>
    </div>
    <div
      [attr.data-cy]="'side-nav-bar'"
      class="c-navigation-bar__side-nav"
      [ngClass]="{ 'c-navigation-bar__side-nav--open': isOpen }"
    >
      <div
        *ngIf="isProfileCollapseOpen"
        class="c-navigation-bar__profile-collapse-overlay"
        (click)="isProfileCollapseOpen = false"
      ></div>
      <div class="c-navigation-bar__side-nav-logo" mcpAllowedRouteNavigation>
        <img class="c-navigation-bar__side-nav-img" [src]="this.logoPortal" [alt]="logoData.alt" />
      </div>
      <div class="c-navigation-bar__items">
        <div class="c-navigation-bar__items-container">
          <mcp-side-navigation
            #sideNav
            (itemClick)="onNavigationItemClick($event)"
            [items]="data?.items"
          ></mcp-side-navigation>
        </div>
        <ng-container *ngTemplateOutlet="profileMenuCollapse"></ng-container>
      </div>
      <div
        [attr.data-cy]="'side-nav-bar__btn'"
        class="c-navigation-bar__side-nav-btn"
        [ngClass]="{ 'is-active': isOpen }"
        (click)="toggleSidenav()"
      >
        <mcp-morph-button [state]="isOpen ? 'close' : ''" [color]="'white'"></mcp-morph-button>
      </div>
    </div>
    <div class="c-navigation-bar__aside">
      <ng-content></ng-content>
      <div class="c-navigation-bar__profile-container" [attr.data-cy]="'profile-header'">
        <mcp-navigable-dropdown
          [model]="profileMenuConfig"
          [selectedItemTemplate]="userProfileHeader"
          (itemClick)="itemClick.emit($event)"
        >
        </mcp-navigable-dropdown>
      </div>
    </div>
  </div>
  <div *ngIf="isOpen" class="c-navigation-bar__side-nav-overlay" (click)="toggleSidenav()"></div>

  <ng-template #userProfileHeader>
    <div class="c-navigation-bar__profile-header">
      <mcui-avatar
        class="c-navigation-bar__profile-header-image"
        [imageSource]="profile?.avatar"
        [text]="profile?.name | firstCharacter: profile?.surname"
        [backgroundColor]="profile?.color || defaultAvatarColor"
        [title]="profile?.name || ''"
      ></mcui-avatar>
      <div class="c-navigation-bar__profile-header-info">
        <p class="c-navigation-bar__profile-header-text">
          <span>{{ profile?.name | trim }}</span
          ><span>{{ ' ' + (profile?.surname | trim) }}</span>
        </p>
      </div>
      <div class="c-navigation-bar__profile-toggle-icon u-icon--arrow-up"></div>
    </div>
  </ng-template>

  <ng-template #profileMenuCollapse>
    <mcui-expansion-panel
      [attr.data-cy]="'profile-header--collapse'"
      class="c-navigation-bar__profile--collapse"
      [class.expanded]="!!isProfileCollapseOpen"
      [expanded]="isProfileCollapseOpen"
      *ngIf="profileMenuConfig"
      (opened)="isProfileCollapseOpen = true"
      (closed)="isProfileCollapseOpen = false"
    >
      <mcui-expansion-panel-header>
        <div class="c-navigation-bar__profile-collapse-divider"></div>
        <ng-container *ngTemplateOutlet="userProfileHeader"></ng-container>
      </mcui-expansion-panel-header>
      <ul class="c-navigation-bar__profile-collapse-options">
        <li
          class="c-navigation-bar__profile-collapse-option"
          *ngFor="let item of profileMenuConfig.items"
          [class.divider]="item.separator"
          (click)="item.command && item.command(); itemClick.emit(item)"
        >
          <i *ngIf="item.icon" class="u-icon u-icon--{{ item.icon }} u-icon--md"></i>
          <span>{{ item.label | translate }}</span>
        </li>
      </ul>
    </mcui-expansion-panel>
  </ng-template>
</ng-container>
