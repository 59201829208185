import { TourStep } from '@shared/modules/tour/models/tour-step.models'
import { runDebouncedPromise } from '@core/utils/common.utils'
import { waitUntilIsRendered } from '@shared/modules/tour/utils/tour.utils'
import { HeaderMenuAction } from '@core/main/components/header/enum/header-menu-actions.enum'
import { NavigableItem } from '@core/main/containers/navigation-bar/models/navigable-item.model'

export const HEADER_MENU_ENTRIES: NavigableItem[] = [
  {
    label: 'P_COMMONS_PROFILE_SECTION_TITLE',
    action: HeaderMenuAction.openProfile,
  },
  {
    label: 'P_COMMONS_PROFILE_LAB_CHANGE_PWD',
    action: HeaderMenuAction.changePassword,
  },
  {
    label: 'P_COMMONS_HEADER_LAB_LOGOUT',
    action: HeaderMenuAction.logout,
    icon: 'logout',
    separatorTop: true,
  },
]

export const ONBOARDING_MENU_ENTRY: NavigableItem = {
  label: 'P_COMMONS_PROFILE_LAB_START_TOUR',
  action: HeaderMenuAction.startTour,
}

export const TOUR_STEPS: TourStep[] = [
  {
    order: 1,
    selector: '#tour-step1',
    name: 'navBarStep',
    preventNavigation: true,
    gtagTitle: 'Navigation bar',
    translationKey: 'MTR_ONBOARDING_NAVBAR_STEP',
  },
  {
    order: 2,
    selector: 'mcui-application-menu .mcui-overlay-panel',
    name: 'menuStep',
    gtagTitle: 'Application launcher',
    translationKey: 'MTR_ONBOARDING_APPMENU_STEP',
    position: 'left',
    preventNavigation: true,
    beforeShow: () =>
      runDebouncedPromise(
        () => (document.querySelector('.mcui-application-menu') as HTMLElement)?.click(),
        0,
      ),
  },
  {
    order: 3,
    selector: 'mcp-widget-live-feed section',
    name: 'liveFeedStep',
    navigateTo: 'inicio',
    preventNavigation: true,
    gtagTitle: 'Live matches',
    translationKey: 'MTR_ONBOARDING_LIVE_FEEDS_STEP',
  },
  {
    order: 4,
    selector: '.home-container .team-summary',
    name: 'homeTeamHeader',
    gtagTitle: 'Home team overview',
    translationKey: 'MTR_ONBOARDING_HOME_HEADER_STEP',
    navigateTo: 'inicio',
  },
  {
    order: 5,
    selector: '.home-container .team-details-container__content',
    name: 'homeTeamDetails',
    gtagTitle: 'Home team details',
    translationKey: 'MTR_ONBOARDING_HOME_DETAILS_STEP',
    position: 'top',
    navigateTo: 'inicio',
  },
  {
    order: 6,
    selector: '.c-matches-list',
    position: 'bottom|right',
    name: 'matchList',
    gtagTitle: 'Match list',
    translationKey: 'MTR_ONBOARDING_MATCHLIST_STEP',
    navigateTo: 'matches',
    hideBackdrop: true,
    waitUntilRendered: ['.c-matches-list .c-list-view'],
    afterShow: () =>
      waitUntilIsRendered('.c-matches-list .c-list-view')
        .then(() =>
          runDebouncedPromise(
            () => document.querySelector('html')?.scrollTo({ top: 0, left: 0, behavior: 'smooth' }),
            0,
          ),
        )
        .then(() =>
          runDebouncedPromise(
            () => (document.querySelector('.c-matches-list__item') as HTMLElement)?.click(),
            700,
          ),
        ),
  },
  {
    order: 7,
    selector: '.match-details__header',
    position: 'bottom',
    name: 'matchHeader',
    permissionUrl: 'match-detail/match-id',
    dependsOn: 'matchList',
    gtagTitle: 'Match timeline',
    translationKey: 'MTR_ONBOARDING_MATCH_HEADER_STEP',
    waitUntilRendered: ['mc-timeline'],
    beforeShow: () =>
      /matches$/.test(location.pathname)
        ? runDebouncedPromise(() => {
            const nodeList = document.querySelectorAll('.c-matches-list__item')
            if (nodeList.length > 3) {
              ;[1, 2].forEach((idx) => (nodeList.item(idx) as HTMLElement)?.click())
            }
          }, 0).then(() =>
            runDebouncedPromise(
              () =>
                (
                  document.querySelector('.match-item-list-footer__button button') as HTMLElement
                )?.click(),
              600,
            ),
          )
        : Promise.resolve(),
  },
  {
    order: 8,
    selector: '.mcp-match-video',
    position: 'top',
    name: 'matchVideo',
    permissionUrl: 'match-detail/match-id',
    dependsOn: 'matchList',
    gtagTitle: 'Match video',
    translationKey: 'MTR_ONBOARDING_MATCH_VIDEO_STEP',
  },
  {
    order: 9,
    selector: '.match-details__metrics',
    position: 'left',
    name: 'matchMetrics',
    permissionUrl: 'match-detail/match-id',
    dependsOn: 'matchList',
    gtagTitle: 'Match tabs',
    translationKey: 'MTR_ONBOARDING_MATCH_METRICS_STEP',
  },
  {
    order: 10,
    selector: '.mcp-team-space',
    position: 'bottom|right',
    name: 'teams',
    gtagTitle: 'Team Space',
    translationKey: 'MTR_ONBOARDING_TEAMS_STEP',
    navigateTo: 'teams',
    hideBackdrop: true,
    // beforeShow: () => {
    //   window.location.reload()
    //   return Promise.resolve()
    // },
    // waitUntilRendered: ['mcp-team-list-container .c-card'],
    // afterShow: () =>
    //   runDebouncedPromise(
    //     () => document.querySelector('html').scrollTo({ top: 0, left: 0, behavior: 'smooth' }),
    //     600,
    //   ).then(() =>
    //     runDebouncedPromise(() =>
    //       document.querySelector('mcp-team-list-container .c-card')?.classList.add('hover'),
    //     ),
    //   ),
    // order: 10,
    // selector: '.c-teams-list__body',
    // position: 'bottom|right',
    // name: 'teams',
    // gtagTitle: 'Team list',
    // translationKey: 'MTR_ONBOARDING_TEAMS_STEP',
    // navigateTo: 'equipos',
    // hideBackdrop: true,
    // waitUntilRendered: ['mcp-team-list-container .c-card'],
    // afterShow: () =>
    //   runDebouncedPromise(
    //     () => document.querySelector('html').scrollTo({ top: 0, left: 0, behavior: 'smooth' }),
    //     600,
    //   ).then(() =>
    //     runDebouncedPromise(() =>
    //       document.querySelector('mcp-team-list-container .c-card')?.classList.add('hover'),
    //     ),
    //   ),
  },
  {
    order: 11,
    selector: '.mcp-player-space',
    position: 'bottom|right',
    name: 'players',
    gtagTitle: 'Player Space',
    translationKey: 'MTR_ONBOARDING_PLAYER_DETAILS_STEP',
    navigateTo: 'players',
    hideBackdrop: true,
    // afterShow: () =>
    //   runDebouncedPromise(
    //     () => document.querySelector('html')?.scrollTo({ top: 0, left: 0, behavior: 'smooth' }),
    //     600,
    //   ).then(() =>
    //     runDebouncedPromise(() =>
    //       document.querySelector('mcp-players-list-container .c-card')?.classList.add('hover'),
    //     ),
    //   ),
    // order: 11,
    // selector: '.c-players-list__body',
    // position: 'bottom|right',
    // name: 'players',
    // gtagTitle: 'Player list',
    // translationKey: 'MTR_ONBOARDING_PLAYERS_STEP',
    // navigateTo: 'jugadores',
    // waitUntilRendered: ['mcp-players-list-container .c-card'],
    // hideBackdrop: true,
    // afterShow: () =>
    //   runDebouncedPromise(
    //     () => document.querySelector('html')?.scrollTo({ top: 0, left: 0, behavior: 'smooth' }),
    //     600,
    //   ).then(() =>
    //     runDebouncedPromise(() =>
    //       document.querySelector('mcp-players-list-container .c-card')?.classList.add('hover'),
    //     ),
    //   ),
  },
  // {
  //   order: 12,
  //   selector: '.mcp-player-space',
  //   name: 'playerDetails',
  //   position: 'bottom|right',
  //   dependsOn: 'players',
  //   gtagTitle: 'Player Space',
  //   translationKey: 'MTR_ONBOARDING_PLAYER_DETAILS_STEP',
  //   hideBackdrop: true,
  //   permissionUrl:
  //     'jugadores/player-id/teams/team-id/competitions/competition-id/seasons/season-id',
  //   // selector: '.player-details-container',
  //   // position: 'bottom|right',
  //   // name: 'playerDetails',
  //   // dependsOn: 'players',
  //   // permissionUrl:
  //   //   'jugadores/player-id/teams/team-id/competitions/competition-id/seasons/season-id',
  //   // gtagTitle: 'Player details',
  //   // translationKey: 'MTR_ONBOARDING_PLAYER_DETAILS_STEP',
  //   // hideBackdrop: true,
  //   // beforeShow: () =>
  //   //   /jugadores$/.test(location.pathname)
  //   //     ? runDebouncedPromise(
  //   //         () => (document.querySelector('.c-card') as HTMLElement).click(),
  //   //         0,
  //   //       ).then(() =>
  //   //         runDebouncedPromise(
  //   //           () =>
  //   //             document.querySelector('html')?.scrollTo({ top: 0, left: 0, behavior: 'smooth' }),
  //   //           600,
  //   //         ),
  //   //       )
  //   //     : Promise.resolve(),
  // },
  {
    order: 12,
    selector: '.c-page.onboarding_monitoring_portal',
    position: 'bottom|right',
    name: 'monitoring',
    gtagTitle: 'Competition monitoring',
    translationKey: 'MTR_ONBOARDING_MONITORING_STEP',
    navigateTo: 'monitoring',
    hideBackdrop: true,
  },
  {
    order: 13,
    selector: '.c-page.onboarding_more_portal',
    position: 'bottom|right',
    name: 'more',
    gtagTitle: 'More',
    translationKey: 'MTR_ONBOARDING_MORE_STEP',
    navigateTo: 'mas',
    hideBackdrop: true,
  },
]

export const TOUR_BUTTONS_TRANSLATIONS = {
  next: 'MTR_ONBOARDING_STEP_NEXT',
  prev: 'MTR_ONBOARDING_STEP_PREV',
  done: 'MTR_ONBOARDING_STEP_DONE',
  close: 'MTR_ONBOARDING_STEP_CLOSE',
  progress: 'MTR_ONBOARDING_STEP',
}
