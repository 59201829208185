import { Router } from '@angular/router'
import { AfterViewInit, Component, ViewChild } from '@angular/core'
import { UntypedFormGroup } from '@angular/forms'

import { iif, Observable, of } from 'rxjs'
import { filter, map, mergeMap, share, switchMap, take, tap } from 'rxjs/operators'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

import {
  AppTheme,
  Competition,
  debounce,
  PLACEHOLDER_IMAGES,
  sortBy,
} from '@mediacoach-ui-library/global'

import { ApplicationMenuConfig, ApplicationName, ApplicationUserProfileType } from '@mediacoach/ui'

import { environment } from '@env'
import { RouterEventService } from '@shared/services/router-event.service'
import { UpdatePasswordModalComponent } from '@shared/components/update-password-modal/update-password-modal.component'
import { DialogType } from '@shared/components/dialog-application/dialog-application.models'
import { Action, Store } from '@ngrx/store'
import { getApplications, getProfile } from '@core/state/selectors/user.selectors'
import { logOut } from '@core/state/actions/oidc.actions'
import {
  saveUserAvatar,
  saveUserCompetition,
  saveUserProfile,
  saveUserTourStep,
  setProfile,
} from '@core/state/actions/profile.actions'
import { Actions, ofType } from '@ngrx/effects'
import { TranslateService } from '@ngx-translate/core'
import { completeWhen } from '@shared/operators/complete-when.operator'
import { McpProfile } from '@auth/models/auth.dto'
import { TeamApi } from '@core/requests/api/team/team.api'
import { ProfileDialogComponent } from '@core/main/components/profile-dialog/profile-dialog.component'
import { CompetitionDialogComponent } from '@core/main/components/competition-dialog/competition-dialog.component'
import { NAVIGATION_LOGO } from '@core/main/containers/navigation-bar/constants/navigation-bar.constants'
import { TourService } from '@shared/modules/tour/services/tour.service'
import {
  HEADER_MENU_ENTRIES,
  TOUR_BUTTONS_TRANSLATIONS,
  TOUR_STEPS,
} from '@core/main/components/header/constants/header.constants'
import { StepActionType } from '@shared/modules/tour/enums/tour.enum'
import { BreakpointObserver } from '@angular/cdk/layout'
import { XL_LEGACY_BREAKPOINT } from '@core/constants/responsive.constants'
import { TourStep, TourStepInfo, TourStepTexts } from '@shared/modules/tour/models/tour-step.models'
import { getCompetitions } from '@core/state/selectors/seasons.selectors'
import { TourOptions } from '@shared/modules/tour/models/tour-options.models'
import { existsAsNumber } from '@core/utils/number.utils'
import { HeaderMenuAction } from '@core/main/components/header/enum/header-menu-actions.enum'
import { PermissionsService } from '@core/services/permissions.service'
import { NavigableItem } from '@core/main/containers/navigation-bar/models/navigable-item.model'
import { analyticsTrackEvent } from '@core/analytics/state/actions/analytics.actions'
import { AnalyticsEvent } from '@core/analytics/enums/gtag-events.enum'
import { AnalyticsParam } from '@core/analytics/enums/gtag-params.enum'
import { AnalyticsCategory } from '@core/analytics/enums/gtag-categories.enum'
import { EventParams } from '@core/analytics/models/gtag.models'

@UntilDestroy()
@Component({
  selector: 'mcp-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss', './header-modal.component.scss'],
})
export class HeaderComponent implements AfterViewInit {
  @ViewChild('profile') profileDialog: ProfileDialogComponent
  @ViewChild('updatePassword') updatePassword: UpdatePasswordModalComponent
  @ViewChild('competition') competition: CompetitionDialogComponent

  private _ranOnce: boolean

  competitions$ = this._store.pipe(getCompetitions(), share())
  applications$: Observable<ApplicationUserProfileType[]> = this._store
    .select(getApplications)
    .pipe(map((data) => data as ApplicationUserProfileType[]))
  profile$: Observable<McpProfile> = this._store.select(getProfile)
  profileMenu: NavigableItem = {
    label: '',
    items: [...HEADER_MENU_ENTRIES],
  }

  placeholder = PLACEHOLDER_IMAGES
  logoData = NAVIGATION_LOGO
  urlStatics: string = environment.STATICS_URL_ASSETS
  darkMode = AppTheme.Dark

  appMenuConfig: ApplicationMenuConfig = {
    environment: environment.envType,
    application: ApplicationName.Portal,
    blacklist: environment.PROFILE_TYPE_BLACKLIST,
  } as any
  applicationDialogType = DialogType

  constructor(
    private readonly _router: Router,
    private readonly _routerEventService: RouterEventService,
    private readonly _translate: TranslateService,
    private readonly _store: Store,
    private readonly _actions$: Actions,
    private readonly _teamApi: TeamApi,
    private readonly _tour: TourService,
    private readonly _breakpointObs: BreakpointObserver,
    private readonly _permissions: PermissionsService,
  ) {}

  private _updateProfile(action: Action, callback: () => void): void {
    this._actions$
      .pipe(
        ofType(setProfile),
        completeWhen(({ profile }) => !!profile),
      )
      .subscribe(() => {
        callback()
      })
    this._store.dispatch(action)
  }

  ngAfterViewInit(): void {
    this._store
      .select(getProfile)
      .pipe(
        filter((p) => !!p?.favourites),
        mergeMap((profile) =>
          iif(
            () => !profile.favourites.competitionId,
            of(''),
            this.competitions$.pipe(
              map((competitions) => ({
                competitions,
                favCompetitionId: profile.favourites.competitionId,
              })),
              filter(
                ({ competitions, favCompetitionId }) =>
                  !!this.profileDialog &&
                  !!competitions &&
                  !competitions.some((c) => c.id === favCompetitionId),
              ),
            ),
          ),
        ),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.competition.open()
      })
  }

  logOut() {
    this._store.dispatch(logOut())
  }

  showChangePassword() {
    this.updatePassword.modal.openModal()
  }

  onSubmit(form: UntypedFormGroup) {
    const photoPathFc = form.get('photoPath')
    if (photoPathFc.dirty) {
      this._store.dispatch(saveUserAvatar({ path: photoPathFc.value }))
    }
    const profile = form.getRawValue()
    delete profile.photoPath
    this._updateProfile(saveUserProfile({ profile }), () => this.profileDialog.close())
  }

  onCompetitionSelected(competition: Competition): void {
    this._updateProfile(saveUserCompetition({ competitionId: competition.id }), () => {
      this.competition.close()
      this._store.dispatch(
        analyticsTrackEvent({
          eventName: AnalyticsEvent.changeFavCompetition,
          eventParams: {
            [AnalyticsParam.category]: AnalyticsCategory.settings,
            [AnalyticsParam.favCompetitionId]: competition.id,
            [AnalyticsParam.favCompetitionName]: competition.name,
          },
        }),
      )
    })
  }

  onItemClick(item: NavigableItem) {
    switch (item.action) {
      case HeaderMenuAction.startTour:
        this.startTour()
        break
      case HeaderMenuAction.logout:
        this.logOut()
        break
      case HeaderMenuAction.changePassword:
        this.showChangePassword()
        break
      case HeaderMenuAction.openProfile:
        this.profileDialog.open()
        break
    }
  }

  onLauncherClick(app) {
    this._store.dispatch(
      analyticsTrackEvent({
        eventName: AnalyticsEvent.openSuiteApplication,
        eventParams: {
          [AnalyticsParam.category]: AnalyticsCategory.navigation,
          [AnalyticsParam.suiteApp]: app.name,
        },
      }),
    )
  }

  onAnalytics(params: { eventName: AnalyticsEvent; eventParams: EventParams }): void {
    this._store.dispatch(analyticsTrackEvent(params))
  }

  startTour(userStoredStep?: number): void {
    const currentPath = this._router.url
    const { order, parsedSteps } = this._parseSteps()

    if (parsedSteps?.length) {
      this._startTour(
        parsedSteps,
        { steps: order, customTexts: this._translateTourButtons() },
        userStoredStep,
      ).subscribe(({ number, actionType }) => {
        this._store.dispatch(
          saveUserTourStep({
            onBoardingStep: actionType === StepActionType.done ? 1 : number,
          }),
        )
        this._router.navigateByUrl(currentPath)
      })
    }
  }

  @debounce(2000)
  private _handleOnBoardingOnStartup(profile: McpProfile): void {
    if (
      existsAsNumber(profile?.onBoardingStep) &&
      profile.onBoardingStep === 0 &&
      !this._ranOnce &&
      this._breakpointObs.isMatched(XL_LEGACY_BREAKPOINT)
    ) {
      this._ranOnce = true
      this.startTour(profile.onBoardingStep)
    }
  }

  private _parseSteps(): { order: string[]; parsedSteps: TourStep[] } {
    const order = []
    const parsedSteps = sortBy(TOUR_STEPS).reduce((steps, step) => {
      if (this._hasPermissions(step)) {
        order.push(step.name)
        steps.push({
          ...step,
          asyncText: this._translate.get(step.translationKey),
        })
      }
      return steps
    }, [])
    return { order, parsedSteps }
  }

  private _hasPermissions(step: TourStep) {
    return (
      (!step.navigateTo && !step.permissionUrl) ||
      (step.navigateTo && this._permissions.isRouteUrlPermitted(step.navigateTo, false)) ||
      (step.permissionUrl && this._permissions.isRouteUrlPermitted(step.permissionUrl, false))
    )
  }

  private _translateTourButtons(): TourStepTexts {
    return Object.keys(TOUR_BUTTONS_TRANSLATIONS).reduce(
      (stepTexts, key) => ({
        ...stepTexts,
        [key]: this._translate.get(TOUR_BUTTONS_TRANSLATIONS[key]),
      }),
      {} as TourStepTexts,
    )
  }

  private _startTour(
    steps: TourStep[],
    options: Partial<TourOptions>,
    userStoredStep?: number,
  ): Observable<TourStepInfo> {
    const _source = existsAsNumber(userStoredStep)
      ? of(userStoredStep)
      : this._store.select(getProfile).pipe(
          completeWhen((p) => !!p),
          map((s) => s.onBoardingStep),
        )

    return _source.pipe(
      map((stepNumber) => steps.find((s) => s.order === stepNumber) || steps[0]),
      tap((initialStep: TourStep) =>
        this._store.dispatch(
          analyticsTrackEvent({
            eventName: AnalyticsEvent.onboardingStart,
            eventParams: {
              [AnalyticsParam.category]: AnalyticsCategory.onboarding,
              [AnalyticsParam.onboardingStepIndex]: initialStep.order,
              [AnalyticsParam.onboardingStepName]: initialStep.gtagTitle,
            },
          }),
        ),
      ),
      switchMap((initialStep: TourStep) =>
        this._tour
          .startTour(steps, {
            ...options,
            startWith: initialStep?.dependsOn ?? initialStep?.name,
          })
          .pipe(
            filter(
              ({ actionType }) =>
                actionType === StepActionType.close || actionType === StepActionType.done,
            ),
            tap(({ actionType, step }) => {
              this._store.dispatch(
                analyticsTrackEvent({
                  eventName:
                    AnalyticsEvent[
                      actionType === StepActionType.close ? 'onboardingStop' : 'onboardingComplete'
                    ],
                  eventParams: {
                    [AnalyticsParam.category]: AnalyticsCategory.onboarding,
                    [AnalyticsParam.onboardingStepIndex]: step.order,
                    [AnalyticsParam.onboardingStepName]: step.gtagTitle,
                  },
                }),
              )
            }),
          ),
      ),
    )
  }
}
