@if (
  {
    connectionCoordinateChange: connectionCoordinateChange$ | async,
    selectedPlayer: selectedPlayer$ | async,
  };
  as fieldDiagram
) {
  <div class="c-field-diagram__container">
    <div *ngIf="title" class="c-field-diagram__title">
      <app-title [title]="title"></app-title>
    </div>

    <mcui-segmented-group
      *ngIf="options?.length"
      class="c-field-diagram__segmented-group"
      [disabled]="!fieldDiagram?.selectedPlayer && !selectedPlayerNumber"
      [ngModel]="segmentValue"
      (ngModelChange)="onSegmentChange($event)"
    >
      @for (opt of options; track opt.id) {
        <mcui-segmented-control [value]="opt.id">{{
          opt.label | translate
        }}</mcui-segmented-control>
      }
    </mcui-segmented-group>

    <div
      class="c-field-diagram__field"
      [class.c-field-diagram__field--empty]="!data?.values?.length"
    >
      <app-loader [loading]="loading">
        <mc-field-diagram
          [type]="type"
          [showPopUp]="showPopUp"
          [fieldDiagramData]="data"
          [forceFromLeft]="true"
          [keepPlayerSelected]="keepPlayerSelected"
          [selectedPlayerNumber]="selectedPlayerNumber"
          [resetWhenClickOutside]="resetWhenClickOutside"
          [arrowDirection]="arrowDirection"
          [isColored]="isColored"
          [playerPopUpTemplate]="playerPopUpTemplate"
          [connectionCoordinateType]="connectionCoordinateType"
          [modifiers]="[FieldDiagramModifier.Gradient, FieldDiagramModifier.FullOpacity]"
          (onPlayerChange)="onPlayerChange.emit($event)"
        >
        </mc-field-diagram>
      </app-loader>
    </div>
  </div>
}
