<div
  class="c-match-item"
  [ngClass]="(extraClasses || '') + ' ' + (direction || '') + ' ' + (display || '')"
>
  <ng-container *ngIf="match?.isLive && showLivePlay">
    <img
      *permissionKey="contentPermission"
      class="c-match-item__play"
      appDefaultImage
      [srcUrl]="playButtonPath"
      [alt]="''"
      [class.disabled]="
        !(match?.streaming?.withCredentials && match?.streaming?.status === status.RUNNING)
      "
      [mcuiTooltip]="
        (match?.streaming?.withCredentials && match?.streaming?.status === status.RUNNING
          ? 'COMMONS_PLAY_LIVE_MATCH'
          : 'COMMONS_LIVE_MATCH_UNAVAILABLE_YET'
        ) | translate
      "
      tooltipPosition="left"
      (click)="playAction.emit({ match, event: $event })"
    />
  </ng-container>
  <div
    *ngFor="let item of templates"
    [ngClass]="
      (item.wrapperCssClasses || '') + ' c-match-item-template c-match-item__' + item.template
    "
    [ngStyle]="item.wrapperStyle"
    [ngSwitch]="item.template"
  >
    <mcp-match-item-info-template
      *ngSwitchCase="templateType.Info"
      [ngStyle]="item.templateStyle"
      [ngClass]="item.templateCssClasses || ''"
      [match]="match"
    ></mcp-match-item-info-template>

    <mcp-match-item-result-template
      *ngSwitchCase="templateType.Result"
      [match]="match"
      [modifiers]="modifiers"
      [isBanner]="isBanner"
      [ngStyle]="item.templateStyle"
      [ngClass]="item.templateCssClasses || ''"
    ></mcp-match-item-result-template>

    <img
      *ngSwitchCase="templateType.Competition"
      class="c-match-item__competition"
      [srcUrl]="match?.competition?.logo"
      [appDefaultImage]="placeholder"
      [ngStyle]="item.templateStyle"
      [ngClass]="item.templateCssClasses || ''"
      [title]="match?.competition?.name || ''"
    />

    <div
      *ngSwitchCase="templateType.Round"
      [ngClass]="item.templateCssClasses || ''"
      [ngStyle]="item.templateStyle"
    >
      {{ 'MATCHES_RESULTS_LAB_ROUND_LETTER' | translate }}
      {{ match.matchday?.number }}
    </div>

    <mcp-match-item-date-template
      *ngSwitchCase="templateType.Date"
      [match]="match"
      [ngClass]="item.templateCssClasses || ''"
      [ngStyle]="item.templateStyle"
    ></mcp-match-item-date-template>

    <mc-match-state
      *ngSwitchCase="templateType.State"
      [matchState]="match?.state"
      [ngStyle]="item.templateStyle"
      [ngClass]="item.templateCssClasses || ''"
      [size]="size"
    ></mc-match-state>

    <app-match-asset-availability
      *ngSwitchCase="templateType.AssetAvailability"
      [ngStyle]="item.templateStyle"
      [ngClass]="item.templateCssClasses || ''"
      [assetAvailability]="match.assetsAvailability"
    ></app-match-asset-availability>

    <div
      *ngSwitchCase="templateType.PlayerParticipation"
      [ngStyle]="item.templateStyle"
      [ngClass]="item.templateCssClasses || ''"
    >
      {{ match.playerParticipation || '-' | translate }}
    </div>

    <div
      *ngSwitchCase="templateType.PlayerMinutesPlayed"
      [ngStyle]="item.templateStyle"
      [ngClass]="item.templateCssClasses || ''"
    >
      {{
        match.playerMinutesPlayed?.value != null
          ? match.playerMinutesPlayed?.value + ((match.playerMinutesPlayed?.unit | translate) || '')
          : '-'
      }}
    </div>
  </div>
</div>
