import { EnvType, UserProfileType } from '@mediacoach-ui-library/global'

import { GetEnvironment } from './environment.constants'
import { POWER_BI_CONFIG_QA } from './environment.qa'

export const environment = {
  ...GetEnvironment(EnvType.Ts),
  production: false,
  GOOGLE_ANALYTICS_TRACKING_CODE: 'UA-113083612-1',
  VIMEO_API_USER: '/109050529',
  PROFILE_TYPE_BLACKLIST: [UserProfileType.LiveProUserProfile],
  ...POWER_BI_CONFIG_QA,
}
