import { Injectable, Injector } from '@angular/core'
import { LoggerService } from '@shared/modules/tour/services/logger.service'
import { TOUR_LOGGER_ENABLED } from '@shared/modules/tour/tokens/tour.tokens'

@Injectable({ providedIn: 'root' })
export class DebuggableBaseService {
  logger: LoggerService

  constructor(injector: Injector) {
    this.logger = injector.get(LoggerService)
    this.logger.enable(injector.get(TOUR_LOGGER_ENABLED))
  }
}
