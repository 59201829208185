import { EnvType, UserProfileType } from '@mediacoach-ui-library/global'

import { GetEnvironment } from './environment.constants'
import { ReportRoute } from '../app/features/reports/enums/reports.enum'

/* eslint-disable @typescript-eslint/naming-convention, max-len */
export const POWER_BI_CONFIG_QA = {
  POWER_BI_DEFAULT_LANG: 'es',
  POWER_BI_WORKSPACE: '2b363260-7547-4c81-92fb-5336ba7c4f75',

  POWER_BI_MONITORING: {
    '2024-2025': [
      {
        route: ReportRoute.teams,
        es: '6f721313-206e-4bbe-aa8c-0fada8031c25',
        en: 'ea840273-64e7-42b5-80c4-271b93f79d32',
      },
      {
        route: ReportRoute.players,
        es: '48697997-57c2-4578-af66-ac948c6f02f0',
        en: 'c7799e01-4056-4aa2-8ee1-a4b4916d1681',
      },
      {
        route: ReportRoute.goalkeepers,
        es: 'd6742513-9a0d-4e61-a216-d5c479a61153',
        en: '56a75822-cc45-454e-afcc-f2357b549a19',
      },
      {
        route: ReportRoute.styleEfficiency,
        es: '1096f9a6-016c-4028-ae25-7b890f2af755',
        en: '576105d4-a217-449d-9416-f236acfceb8c',
      },
      {
        route: ReportRoute.playerStats,
        es: '1a89727b-b68d-41e0-8fd0-b2444718dd87',
        en: '8c380bfb-87b7-44b1-a8fe-02ce38eaaca8',
      },
      {
        route: ReportRoute.accumulatedTeams,
        es: '38ee68de-99e7-4823-a62a-76f0eec5616f',
        en: '41777051-10df-447e-8d5d-413e6e9f3fb4',
      },
      {
        route: ReportRoute.physicalPerformance,
        es: '08a9254b-df31-49fb-bc38-567cfbf3e127',
        en: '0682e822-87d5-4534-b115-9014f7047328',
      },
      {
        route: ReportRoute.physicalIntervals,
        es: '62dec1aa-b48f-43d2-a786-8694626cb4ac',
        en: 'd6479d13-3629-4cea-8d10-a617e6b76c00',
      },
      {
        route: ReportRoute.referees,
        es: '6bc732ef-98f6-436d-9cc1-4d39fc5542a9',
        en: 'ccb5b500-e0c6-41b3-b40a-f4fb6a105226',
      },
      {
        route: ReportRoute.gameInterruptions,
        es: '84ae4f5d-b5c4-4498-8352-85f23029d251',
        en: '86b157fb-532e-48ec-96fe-a5737e74d41e',
      },
      {
        route: ReportRoute.compEvolutionEffectiveTime,
        es: '6bc732ef-98f6-436d-9cc1-4d39fc5542a9', //FIXME: Mock ID
        en: 'ccb5b500-e0c6-41b3-b40a-f4fb6a105226', //FIXME: Mock ID
      },
      {
        route: ReportRoute.finishing,
        es: '23fe33d8-aa07-4ec6-bd66-fe81c25cfdf5',
        en: '5cbb68cf-5a9f-4849-bd79-108d19d93719',
      },
      {
        route: ReportRoute.playModels,
        es: 'c221a3ac-8980-4aa4-975b-e9a61fe3f305',
        en: '4acd49ca-8a1e-4023-8a7b-dd2a5a69ac9b',
      },
    ],

    '2023-2024': [
      {
        route: ReportRoute.teams,
        es: 'e7fc4ae4-7a9f-4372-bafc-a820a2c7d28d',
      },
      {
        route: ReportRoute.players,
        es: 'bc34ff52-4fed-485d-a81d-f963d61fd548',
      },
      {
        route: ReportRoute.goalkeepers,
        es: '4f7d8d02-82d1-4497-af48-14add47290ec',
      },
      {
        route: ReportRoute.styleEfficiency,
        es: '4baad447-3f07-4a39-8ea1-276c261d1176',
      },
      {
        route: ReportRoute.playerStats,
        es: '17a91002-b318-4b98-86d4-26e593a414b3',
      },
      {
        route: ReportRoute.accumulatedTeams,
        es: 'fe4cfcbf-6cb2-4747-a148-5737caa07a9d',
      },
      {
        route: ReportRoute.physicalPerformance,
        es: '50c6d241-a11e-42b8-8536-d31034f28570',
      },
      {
        route: ReportRoute.physicalIntervals,
        es: '25fc8f72-2393-40dd-b94c-953d6e69aa59',
      },
      {
        route: ReportRoute.referees,
        es: '8cc33fec-4157-4ab6-bf9b-c67df415e1d7',
      },
      {
        route: ReportRoute.gameInterruptions,
        es: 'ae219a7e-89f1-44c6-a779-0f630236ac61',
      },
      {
        route: ReportRoute.compEvolutionEffectiveTime,
        es: '5a85e167-bef0-4bb6-b74b-4d1e998bf648', //FIXME: Mock ID
      },
      {
        route: ReportRoute.finishing,
        es: '0af725fd-cf5e-45a0-a0ac-5c680639dd61',
      },
    ],
    '2022-2023': [
      {
        route: ReportRoute.teams,
        es: 'b94ec49f-2bbe-4e2f-90ba-97b6133397c6',
      },
      {
        route: ReportRoute.players,
        es: 'b9bb50b5-374f-4573-966a-11551d7a634f',
      },
      {
        route: ReportRoute.goalkeepers,
        es: '2989f9da-5511-43d5-8c74-09da4514195d',
      },
      {
        route: ReportRoute.styleEfficiency,
        es: 'e2fe4748-4800-4086-aca5-3fa728163236',
      },
      {
        route: ReportRoute.playerStats,
        es: 'ce68ec61-a238-4dd3-8d92-65cec450c700',
      },
      {
        route: ReportRoute.accumulatedTeams,
        es: '862612ce-19cf-4ec2-94ad-6c772c44d568',
      },
      {
        route: ReportRoute.physicalPerformance,
        es: '3a48b52a-3956-4fb2-bddc-ff89b5cff1dc',
      },
      {
        route: ReportRoute.physicalIntervals,
        es: 'b8bac76a-0da2-4454-85aa-09ee903f3908',
      },
      {
        route: ReportRoute.referees,
        es: '93a1acb9-26ce-4fa3-84c3-15a37836fb75',
      },
      {
        route: ReportRoute.gameInterruptions,
        es: '89b52e23-ad66-4071-a041-935058929fff',
      },
      {
        route: ReportRoute.compEvolutionEffectiveTime,
        es: '5a85e167-bef0-4bb6-b74b-4d1e998bf648', //FIXME: Mock ID
      },
    ],
    '2021-2022': [
      {
        route: ReportRoute.teams,
        es: '7b9bf1be-0b41-47cc-b104-c8465fd3a9be',
      },
      {
        route: ReportRoute.players,
        es: 'e00ac0ce-b33b-45f6-a4d7-2dcb3cf3924b',
      },
      {
        route: ReportRoute.goalkeepers,
        es: 'eda78d4f-6a28-4ea3-8d10-fb35f4dd90ca',
      },
      {
        route: ReportRoute.styleEfficiency,
        es: 'acf3308e-01ac-42c2-9d19-966ee1cfcc39',
      },
      {
        route: ReportRoute.playerStats,
        es: '84498232-8630-46ed-b40d-c915a6c2b9d6',
      },
      {
        route: ReportRoute.accumulatedTeams,
        es: '2f2367c8-eaf7-4fae-816e-34aa3bcf57ef',
      },
      {
        route: ReportRoute.physicalPerformance,
        es: 'aed80147-ae0d-44d4-abdc-e46a2b9a9193',
      },
    ],
    '2020-2021': [
      {
        route: ReportRoute.teams,
        es: '68da2548-ed01-4e63-b1f5-e645ac13aea6',
      },
      {
        route: ReportRoute.players,
        es: '5e08071b-42ee-4fa4-a5cf-98ae67459f3e',
      },
      {
        route: ReportRoute.goalkeepers,
        es: 'b9a68aa7-b11a-4b07-a13f-5747a36e5bcf',
      },
      {
        route: ReportRoute.styleEfficiency,
        es: '05062e3c-a526-4f53-ac31-806b67558969',
      },
      {
        route: ReportRoute.playerStats,
        es: '7f42cf3b-1b37-48dd-a3e7-0562970d835c',
      },
      {
        route: ReportRoute.accumulatedTeams,
        es: '7133b91e-9e5a-45ed-a4f4-1f8816637cf8',
      },
      {
        route: ReportRoute.physicalPerformance,
        es: 'fd997bea-edce-4f71-908f-f09be5a19f0f',
      },
    ],
    '2019-2020': [
      {
        route: ReportRoute.teams,
        es: '954886ad-561b-405e-96f3-3ee01a85d084',
      },
      {
        route: ReportRoute.players,
        es: '1612b744-0905-4053-a011-93705e23641b',
      },
      {
        route: ReportRoute.goalkeepers,
        es: '4b74602d-9574-4bf4-a390-908a90675586',
      },
      {
        route: ReportRoute.styleEfficiency,
        es: '755a27bd-bd78-4936-84fa-f48be4e87daa',
      },
      {
        route: ReportRoute.playerStats,
        es: '2f1e7928-f992-47a3-8119-73804ecc11bd',
      },
      {
        route: ReportRoute.accumulatedTeams,
        es: '8b217e21-9109-4176-9574-2e04f31cb023',
      },
      {
        route: ReportRoute.physicalPerformance,
        es: '5153cafa-bc68-4e87-b6e4-e7c78d38a4ca',
      },
    ],
    '2018-2019': [
      {
        route: ReportRoute.teams,
        es: 'eb9cc4c4-b4ef-4a92-a264-7436d7404c48',
      },
      {
        route: ReportRoute.players,
        es: '206ec7c1-fc60-468f-b77b-79d72f6fbf35',
      },
    ],
  },
  POWER_BI_METRICS_LAB: {
    '2024-2025': [
      {
        route: ReportRoute.teamsMl,
        es: '2ddff128-2f51-467e-94b4-728500e9dfd5',
        en: '1fa770f7-7d21-4858-8836-ad45414dc5b7',
      },
      {
        route: ReportRoute.playersMl,
        es: 'c2b7fd2c-f41d-486f-a72a-9f3c4f6ea2c6',
        en: '13c61a2a-2cff-4c36-9962-c65e489bbbf6',
      },
      {
        route: ReportRoute.playersMLPhysical,
        es: 'c341e7d9-f5ca-40a6-bfb3-13b6699c0d08',
        en: '41bd0ccc-e025-4d68-99f1-e58c9584f333',
      },
      {
        route: ReportRoute.wcsMl,
        es: 'b2115ecd-2019-463e-89d5-c5238c50b6eb',
        en: 'e31c1091-ba0c-4823-ae95-9667c4986001',
      },
    ],
    '2023-2024': [
      {
        route: ReportRoute.teamsMl,
        es: 'e3a6ab27-185f-4b25-a52a-7da02a1b2572',
      },
      {
        route: ReportRoute.playersMl,
        es: 'b29cb28b-f6ee-4dc4-adf1-51c3b31c46bf',
      },
      {
        route: ReportRoute.playersMLPhysical,
        es: '655a72ed-05ee-482b-9769-4d14f7577e23',
      },
      {
        route: ReportRoute.wcsMl,
        es: '72705bcd-cf26-4d4b-a19b-f80bdb178073',
      },
    ],
    '2022-2023': [
      {
        route: ReportRoute.teamsMl,
        es: 'ada9a9dd-3fb7-45fd-bad5-273d83b73bbd',
      },
      {
        route: ReportRoute.playersMl,
        es: '711d8454-458e-447d-8313-606810bfa342',
      },
      {
        route: ReportRoute.playersMLPhysical,
        es: '1e0634cd-4988-42f7-8b3d-1699015cfbf2',
      },
      {
        route: ReportRoute.wcsMl,
        es: '6e2d59b7-d302-4f27-9d4c-3a3342b621bd',
      },
    ],
    '2021-2022': [
      {
        route: ReportRoute.teamsMl,
        es: '2c782063-0d27-4956-9acb-e4532fa838a1',
      },
      {
        route: ReportRoute.playersMl,
        es: '3db63977-4b36-4ff5-86e1-362ab6fcd8c9',
      },
      {
        route: ReportRoute.wcsMl,
        es: '2a1b3c4a-1a76-48f2-bc1e-305352cd5439',
      },
    ],
  },
}
/* eslint-enable @typescript-eslint/naming-convention, max-len */

export const environment = {
  ...GetEnvironment(EnvType.Qa),
  production: true,
  GOOGLE_ANALYTICS_TRACKING_CODE: 'UA-113083612-1',
  VIMEO_API_USER: '/109050529',
  PROFILE_TYPE_BLACKLIST: [UserProfileType.LiveProUserProfile],
  ...POWER_BI_CONFIG_QA,
}
