import { ApiTeam } from '../../models/models/team.models'
import {
  parseStatic,
  ParseStatics,
  StaticOrientation,
  toLowerCaseNoAccent,
} from '@mediacoach-ui-library/global'
import { ApiPlayer } from '@core/models/models/player.models'
import { getMetricPlayerName } from '@core/utils/player.utils'
import {
  PLAYER_DEMARCATION_FALLBACK_KEY,
  PLAYER_DEMARCATION_KEYS,
  SORTED_POSITIONS,
} from '@core/constants/player.constants'
import { PlayerPosition } from '@core/enums/player.enums'
import { DemarcationPipe } from '@shared/pipes/demarcation/demarcation.pipe'
import { HighlightPipe } from '@mediacoach/ui'

export const SPACE_MIN_CHAR_TO_FILTER = 3

export const searchTeamsByText = (teams: ApiTeam[], text: string): ApiTeam[] => {
  if (text) {
    return (
      teams &&
      (!text || text.length < SPACE_MIN_CHAR_TO_FILTER
        ? teams
        : teams.filter(({ name }) => {
            return toLowerCaseNoAccent(name).trim().indexOf(text) !== -1
          }))
    )
  }

  return teams
}

export const mapSpaceSearchPlayers = (
  players: ApiPlayer[],
  text: string,
  _demarcationPipe: DemarcationPipe,
  _highlightPipe: HighlightPipe,
) =>
  players
    .map((player) => {
      return {
        ...player,
        ...ParseStatics(player.statics, [StaticOrientation.Portrait]),
        imgUrl: parseStatic(player.statics, StaticOrientation.Portrait),
        imgAlt: player.formattedName ? player.formattedName : '-',
        teamShield: parseStatic(player.teamStatics, StaticOrientation.Portrait),
        metricPlayerName: getMetricPlayerName(player),
        shirtNumber: player.shirtNumber ? player.shirtNumber : '-',
        name: player.formattedName
          ? _highlightPipe.transform(player.formattedName, text, true)
          : '-',
        positionOrder: SORTED_POSITIONS.indexOf(player.playerPosition as PlayerPosition),
        position: _demarcationPipe.transform(
          player,
          PLAYER_DEMARCATION_KEYS,
          PLAYER_DEMARCATION_FALLBACK_KEY,
          ' ',
        ),
      } as any
    })
    .sort(
      (a, b) =>
        a.positionOrder - b.positionOrder || (a.shirtNumber as number) - (b.shirtNumber as number),
    )
