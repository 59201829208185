<div class="c-heat-map__container">
  <div *ngIf="title" class="c-heat-map__title">
    <app-title [title]="title"></app-title>
  </div>
  <mcui-segmented-group
    [ngModel]="segmentValue"
    (ngModelChange)="segmentValue = $event; onChange.emit($event)"
    class="c-heat-map__segment-group"
    name="heatmap"
  >
    @for (opt of options; track opt.id) {
      <mcui-segmented-control [value]="opt.id">
        <div class="c-heat-map__segment-text">{{ opt.label | translate }}</div>
      </mcui-segmented-control>
    }
  </mcui-segmented-group>
  <div
    class="c-heat-map__content"
    [class.c-heat-map__content--empty]="!(dataDictionary[segmentValue] || []).length"
  >
    <app-loader [loading]="loading">
      <mc-heat-map
        [heatMapData]="dataDictionary[segmentValue] || []"
        [arrowDirection]="arrowDirection"
        [heatMapConfig]="heatMapConfig"
        [isColored]="isColored"
      >
      </mc-heat-map>
    </app-loader>
  </div>
</div>
